import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { animationsPop, animationsOnHeight, animationsSlideInOut } from "./animations";

@Component({
    selector: 'notyf-toast-component',
    templateUrl: './toast.component.html',
    animations: [animationsPop],
    preserveWhitespaces: false,
    standalone: false
})
export class ToastComponent extends Toast {

    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
    ) {
        super(toastrService, toastPackage);
    }
}
